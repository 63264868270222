<template>
  <div id="profile">
    <p class="title">{{ $t('settings.profile.title') }}</p>

    <md-field>
      <label>{{ $t('settings.profile.firstName') }}</label>
      <md-input v-model="profile.firstName"></md-input>
    </md-field>

    <md-field>
      <label>{{ $t('settings.profile.lastName') }}</label>
      <md-input v-model="profile.lastName"></md-input>
    </md-field>

    <md-field>
      <label>{{ $t('settings.profile.middleName') }}</label>
      <md-input v-model="profile.middleName"></md-input>
    </md-field>

    <md-field>
      <label>{{ $t('settings.profile.gender') }}</label>
      <md-select v-model="profile.gender">
        <md-option value="MALE">{{ $t('settings.profile.male') }}</md-option>
        <md-option value="FEMALE">{{
          $t('settings.profile.female')
        }}</md-option>
      </md-select>
    </md-field>

    <md-datepicker
      v-model="profile.birthDate"
      :md-disabled-dates="disabledDates"
      :md-disabled-input="true"
    >
      <label>{{ $t('settings.profile.birthDate') }}</label>
    </md-datepicker>

    <md-field>
      <label>{{ $t('settings.fellowtravelers.documentType') }}</label>
      <md-select v-model="profile.personIdType" readonly disabled>
        <md-option value="Passport">{{
          $t('settings.fellowtravelers.documentTypes.passport')
        }}</md-option>
      </md-select>
    </md-field>

    <md-field>
      <label>{{ $t('settings.fellowtravelers.documentNumber') }}</label>
      <md-input v-model="profile.personId" readonly disabled></md-input>
    </md-field>

    <b-alert v-model="error" variant="danger" dismissible>
      {{ $t('settings.fillAllField') }}
    </b-alert>

    <div class="save" @click="apply">{{ $t('settings.save') }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      profile: {},
      error: false,
    }
  },
  methods: {
    apply() {
      let mandatory = ['firstName', 'lastName', 'gender', 'birthDate']
      let allFilled = true
      mandatory.forEach(field => {
        if (
          typeof this.profile[field] == 'undefined' ||
          this.profile[field] == ''
        ) {
          this.error = true
          allFilled = false
        }
      })
      if (!allFilled) return

      this.$api
        .patch(`user`, {
          firstName: this.profile.firstName.trim(),
          middleName: String(this.profile.middleName || '').trim(),
          lastName: this.profile.lastName.trim(),
          gender: this.profile.gender,
          birthDate: new Date(this.profile.birthDate).normalize(),
        })
        .then(() => {
          window.location.reload()
        })
    },
    disabledDates(date) {
      return date > new Date()
    },
  },
  mounted() {
    this.$api.get(`user`).then(res => {
      this.profile = res.data.data
    })
  },
}
</script>

<style lang="scss">
#profile {
  padding: 0 15px 0 15px;
  margin: 0 auto;
  max-width: 500px;
  font-family: Roboto;

  > .title {
    color: #47525e;
    font-size: 32px;
    line-height: 30px;
    font-family: Roboto;
  }

  .save {
    background-color: #47525e;
    float: right;
    border-radius: 5px;
    height: 35px;
    width: 121px;
    line-height: 35px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s all;
    &:hover {
      filter: brightness(1.2);
    }
    font-family: Roboto;
  }
}
</style>
